// Uses https://github.com/ganlanyuan/tiny-slider

const tns = require('../vendor/tiny-slider.min.js');

module.exports = () => {

  // We'll make this better at some point
  const slideElements = document.querySelectorAll('.quote-slider');

  if (slideElements.length) {
    for (let i = 0; i < slideElements.length; i += 1) {
      const items = slideElements[i].querySelectorAll('.quote-slider__item');
      if (items.length > 1) {
        var slider = tns({
          container: '.quote-slider',
          navPosition: 'bottom',
          controls: 'false',
          items: 1,
          slideBy: 'page',
          "mouseDrag": true
        });
      }
    }
  }
}