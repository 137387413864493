module.exports = () => {

document.querySelectorAll('a[href^="#"]').forEach(anchor => {
  const anchorTargetId = anchor.getAttribute("href").replace("#", "");
  const anchorTarget = document.getElementById(anchorTargetId);
  if (anchorTargetId.length) {
    anchor.addEventListener('click', function (e) {
        e.preventDefault();
        anchorTarget.setAttribute("tabindex", "-1");
        document.querySelector(this.getAttribute('href')).scrollIntoView({
            behavior: 'smooth'
        });
        anchorTarget.focus();
    });
    anchorTarget.addEventListener('blur', function () {
      anchorTarget.removeAttribute("tabindex");
    });
  }
});

}