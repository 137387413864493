module.exports = () => {
  if (document.body.getAttribute("data-theme") == "coforma"){
    const navItems = document.getElementById("nav-items");
    const skipNav = document.getElementById("skipnav");
    const mainNav = document.getElementById("header-nav");
    let mouseIsOnNav = false;
    
    mainNav.addEventListener("mouseover", function(e) {
      mouseIsOnNav = true;
    });
    
    mainNav.addEventListener("mouseleave", function(e) {
      mouseIsOnNav = false;
      mainNav.classList.remove("force-hide");
    });
    
    window.addEventListener("keydown", function(e) {
      if(mouseIsOnNav && e.key === "Escape") {
        mainNav.classList.add("force-hide");
      }
    });
    
    navItems.addEventListener("keydown", function(e) {
      if(e.key === "Escape") {
        skipNav.focus();
      }
    });
    
    skipNav.addEventListener("keydown", function(e) {
      if(e.key === "Escape") {
        skipNav.blur();
      }
    });    
  }
};