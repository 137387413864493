module.exports = () => {
  
  const internalNav = document.getElementById('internal-nav');

  if (typeof(internalNav) != 'undefined' && internalNav != null) {

    const internalNavLinks = internalNav.querySelectorAll('.internal-nav__link');
    const CURRENT_CLASS = 'current';
    const internalNavHeight = internalNav.clientHeight;

    window.addEventListener('scroll', function handleScroll(e) {
      let scrollPos = window.pageYOffset;

      for (let i = 0; i < internalNavLinks.length; i += 1) {
        let currentLink = internalNavLinks[i];
        let referenceElement = document.getElementById(currentLink.hash.replace('#', ''));

        if (referenceElement && referenceElement.offsetTop <= (scrollPos + internalNavHeight)) {
          internalNavLinks.forEach(el => el.classList.remove(CURRENT_CLASS));
          currentLink.classList.add(CURRENT_CLASS);
        }
        else {
          currentLink.classList.remove(CURRENT_CLASS);
        }
      }
    });

    // We need click events to handle keyboard navigation
    for (let i = 0; i < internalNavLinks.length; i += 1) {

      // Now we focus on the section so the user can keyboard interact with that content
      internalNavLinks[i].addEventListener("click", function() {
        
        // Timeout prevents collisions with smooth scrolling.
        setTimeout(function(){
          internalNavLinks.forEach(el => el.classList.remove(CURRENT_CLASS));
          internalNavLinks[i].classList.add(CURRENT_CLASS);
        }, 750);
      });
    }; 
  }
}