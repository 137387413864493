const konami = require('./scripts/konami.js')();

window.coforma = {
  svg4everybody: require('./shims/svg4everybody'),
  tinySlider: require('./scripts/sliders.js'),
  stickyNav: require('./scripts/nav.js'),
  mobileNav: require('./scripts/mobile-nav.js'),
  internalNav: require('./scripts/internal-nav.js'),
  smoothScroll: require('./scripts/smooth-scroll.js'),

  testContactForm: (submit) => {
    const values = {
        id_first_name: 'George',
        id_last_name: 'Jetson',
        id_email_address: 'yoni+george@coforma.io',
        id_phone_number: '888-888-8888',
        id_subject: 'This is a test subject',
        id_message: 'You may know exactly what you’re looking for, or you may simply be curious about who we are and what we’ve done. We welcome the opportunity to hear from you, and learn about your concerns and motivations.',
    };
    Object.keys(values).forEach(v => {
        document.getElementById(v).value = values[v];
    });
    if(submit) {
      document.getElementById('recaptcha').click();
    }
  }, // testContactForm

  checkEggs: () => {
    const egg = document.querySelector('[data-egg]');
    if(egg) {
      konami.add({fn:coforma.loadEgg, persist: false, name: egg.getAttribute('data-egg')});
      egg.addEventListener('dblclick', coforma.loadEgg, false);
    }
    if(location.hash.indexOf('egg')>=0) {
      coforma.loadEgg(egg.getAttribute('data-egg'));
    }
  }, // checkEggs

  loadEgg: (egg) => {
    egg = typeof egg==='string' ? egg : document.querySelector('[data-egg]').getAttribute('data-egg');
    const siteJs = document.getElementById('site-js');
    const script = document.createElement('script');
    script.src = siteJs.src.replace(/website.*/, `eggs/${egg}.js?v=${new Date().getTime()}`);
    script.id = 'egg-js';
    document.body.appendChild(script);
  }, // loadEgg

  init: () => {
    coforma.svg4everybody();
    coforma.tinySlider();
    coforma.stickyNav();
    coforma.mobileNav();
    coforma.internalNav();
    coforma.smoothScroll();
    coforma.checkEggs();
  }
};

window.onload = coforma.init;