module.exports = () => {
    const pattern = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];
    let current = 0;
    let callbacks = [];
    
    const konamiKeyHandler = (e) => {
	    if(pattern.indexOf(e.key) < 0 || e.key !== pattern[current]) {
		    current = 0;
		    return;
	    }
        current++;

        if(pattern.length === current) {
            current = 0;
            let callback = callbacks.shift();
            callback.fn(callback.name);
            if(callback.persist) {
                callbacks.push(callback);
            }
            if(callbacks.length===0) {
                document.removeEventListener('keydown', konamiKeyHandler);
            }
        }
    };

    return {
        add: (callback) => {
            callbacks.push(callback);
            document.addEventListener('keydown', konamiKeyHandler, false);
        }
    }
};