const TrapFocus = require('../utils/trap-focus.js');

module.exports = () => {

  const CLOSE_BUTTON_ID = 'open-nav',
        OPEN_NAV_BUTTON = document.getElementById(CLOSE_BUTTON_ID),
        CLOSE_NAV_BUTTON = document.getElementById('close-nav'),
        HEADER = document.getElementById('header-nav'),
        NAV_MENU = document.getElementById('nav'),
        ROOT = document.getElementsByTagName( 'html' )[0],
        OPEN_CLASS = "is-visible",
        READY_CLASS = "is-ready",
        NAV_OPEN_BODYCLASS = "nav-open",
        OVERLAY = document.createElement("div"),
        OVERLAY_CLASS = "overlay";

  const toBeInert = document.querySelectorAll(
    `body > *:not(.header):not([aria-hidden]), .header__wrapper > *:not(#nav)`
  );

  const forEach = function (array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
      callback.call(scope, i, array[i]); // passes back stuff we need
    }
  };

  const closeNav = function() {
    NAV_MENU.removeEventListener("keydown", closeNav, false);   
    ROOT.classList.remove(NAV_OPEN_BODYCLASS);
    NAV_MENU.classList.remove(OPEN_CLASS);
    OPEN_NAV_BUTTON.focus();
    OVERLAY.classList.remove(OPEN_CLASS);

    forEach(toBeInert, function (index, value) {
      value.removeAttribute("aria-hidden");
    });

    setTimeout(function(){
      NAV_MENU.classList.remove(READY_CLASS);
      document.querySelector(`.${OVERLAY_CLASS}`).remove();
    }, 100);
  }

  const openNav = function() {
    ROOT.classList.add(NAV_OPEN_BODYCLASS);
    NAV_MENU.classList.add(READY_CLASS);
    HEADER.appendChild(OVERLAY);
    OVERLAY.classList.add(OVERLAY_CLASS);
    OVERLAY.classList.add(READY_CLASS);
    OVERLAY.setAttribute("aria-hidden", "true");

    setTimeout(function(){
      NAV_MENU.classList.add(OPEN_CLASS);
      OVERLAY.classList.add(OPEN_CLASS);
    }, 10);

    TrapFocus(NAV_MENU);

    forEach(toBeInert, function (index, value) {
      value.setAttribute("aria-hidden", "true");
    });
  }

  const resize = function(){
    const closer = document.getElementById(CLOSE_BUTTON_ID);
    if (closer && closer.getBoundingClientRect().width === 0) {
      // When the mobile nav is active, and the close box isn't visible,
      // we know the user's viewport has been resized to be larger.
      // Let's make the page state consistent by deactivating the mobile nav.
      closeNav();
    }
  }

  CLOSE_NAV_BUTTON.addEventListener("click", function() {
    closeNav();
  });

  OVERLAY.addEventListener("click", function() {
    closeNav();
  });

  window.addEventListener("resize", function() {
    if (ROOT.classList.contains(NAV_OPEN_BODYCLASS)){
      resize();
    }
  });

  NAV_MENU.addEventListener("keydown", function(e) {
    if(e.key === "Escape") {
      closeNav();
    }
  });

  OPEN_NAV_BUTTON.addEventListener("click", function() {
    openNav();
  });
};